<template>
  <el-dialog
  v-dialogDrag
    title="融资单详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div class="auditCon">
      <p class="auditNote">审核意见：{{ form.note }}</p>
    </div>
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资单号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCode }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">资方名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.factorName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单编号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orderCode }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.commodityName }}</p>
            </el-col>
          </el-row>
        </el-col>
           <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单付款状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.payStatus | payStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单仓储状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.arrivalStatus | arrivalStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">买方违约状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.defaultBuyStatus | buyersDefaultStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">卖方违约状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.defaultSellStatus | sellersDefaultStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
         <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资比例：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">---</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资金额：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeAmount }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">当前融资时长：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financePaybackDays }}天</p>
            </el-col>
          </el-row>
        </el-col> 
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">当前融资利息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCurrentFee }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeStatus | financeStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">融资逾期利息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCurrentLateFee }}元</p>
            </el-col>
          </el-row>
        </el-col>
           <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">审批时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.handleTime  | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">逾期利息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financeCurrentLateFee }}元</p>
            </el-col>
          </el-row>
        </el-col>
       -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">还款期限：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financePaybackLimit }}天</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">应还款时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.financePaybackTime | DateFormateDay }} 前</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">已还款金额：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.paybackAmount }}元</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">还款时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.paybackTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">还款状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.paybackStatus | paybackStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        warehousId: null,
        price: null
      }
    };
  },
  methods: {
    showDialog(row) {
      console.log(row)
      this.dialogVisible = true;
      this.getDetails(row);
    },
    getDetails(id) {
      protocolFwd.param_findFinanceInfoDetailFrontOrder.param.financeId = id;
      http.postFront(protocolFwd.param_findFinanceInfoDetailFrontOrder).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            value.specificationsName = this.attributeTrans(value.specifications)
            this.form = value;
            for (let i in this.form) {
              if (this.form[i] === null) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
.auditCon {
  position: absolute;
  left: 20%;
  top: 20px;
}
</style>