<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>我的融资单</p>
      </div>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="financeCode" label="融资单号"></el-table-column>
        <el-table-column prop="commodityName" label="商品分类"></el-table-column>
        <el-table-column prop="factorName" label="资方名称"></el-table-column>
        <el-table-column prop="orderCode" label="订单编号"></el-table-column>
        <el-table-column prop="paybackTime" label="还款时间">
           <template slot-scope="scope">
            <span>{{ scope.row.paybackTime | DateFormate }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="commoditySpecifications" label="品种"></el-table-column> -->
        <el-table-column prop="financeAmount" label="融资金额">
           <template slot-scope="scope">{{ scope.row.financeAmount }}元</template>
        </el-table-column>
        <el-table-column prop="financeStatus" label="融资状态">
          <template slot-scope="scope">
            {{ scope.row.financeStatus | financeStatusFormate }}
          </template>
        </el-table-column>
        <el-table-column prop="paybackStatus" label="还款状态">
          <template slot-scope="scope">
            {{ scope.row.paybackStatus | paybackStatusFormate }}
          </template>
        </el-table-column>
        <el-table-column prop label="应还款时间" min-width="105px">
         <template slot-scope="scope">
            {{ scope.row.financePaybackTime | DateFormateDay }} 前
          </template>
        </el-table-column>
         <el-table-column prop label="操作" width="180">
          <template slot-scope="scope">
            <div class="operateButtons">
              <el-button  @click="viewDialog(scope.row)" type="primary" size="mini">查看</el-button>
              <el-button  v-if="scope.row.financeStatus==1||scope.row.financeStatus==4" @click="redeem(scope.row)" type="primary" size="mini">赎回</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
       <!-- 查看 -->
    <viewDialog ref="viewDialog" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import viewDialog from "./viewDialog";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        name: null,
        warehouse: null
      },
      dialogVisible: false,
      delivery: {
        warehousId: null,
        type: 0
      },
      source: 1,
      right: {
        delivery: false
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    viewDialog
  },
  mounted() {
    this.getDatas();
    // this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.delivery = this.isHasRight('trade-delivery-warehous');
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_findFinanceInfoFrontOrder.param.page = this.current - 1;
      protocolFwd.param_findFinanceInfoFrontOrder.param.size = this.pageSize;
      protocolFwd.param_findFinanceInfoFrontOrder.param.sort = [{property: "financeId", direction: "DESC"}];
      http.postFront(protocolFwd.param_findFinanceInfoFrontOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDialog(row) {
       this.$refs.viewDialog.showDialog(row.financeId);
    },
    redeem(row) {
      console.log(row.financeReturnAmount,row.paybackAmount)
      //manualPayback
      //financeReturnAmount:融资应还款金额      paybackAmount:已还款金额
      let amount = row.financeReturnAmount - row.paybackAmount
       this.$confirm("请确认是否主动还款："+ amount +'元', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          protocolFwd.param_manualPayback.param.financeId = row.financeId;
          http.postFront(protocolFwd.param_manualPayback).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
   
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
